import { truncate, dateFormater } from '@/filters'

export default {
  name: 'WorshipItem',
  filters: {
    truncate,
    dateFormater
  },
  props: {
    worship: {
      type: Object,
      default: {}
    }
  },
  methods: {
    handleEditWorship () {
      this.$emit('editWorship', this.worship)
    }
  }
}
